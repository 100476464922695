import React from "react";
import Helmet from "react-helmet";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import notFoundPage from "assets/jss/material-kit-react/views/notFoundPage.jsx";



const dashboardRoutes = [];

class NotFound extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
        <div>
            <Helmet>
                <title>BookMerlin</title>
            </Helmet>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.notFound}>
                <img 
                  className={classes.img}
                  src={require('assets/img/logo001_green.png')}  
                />
                <h1 className={classes.title}>Oops...</h1>
                <h3 className={classes.subtitle}>We couldn't find the page you were looking for.</h3>
                <Link className={classes.inviteLink} to="/">Click here to get your invitation!</Link>
              </GridItem>
            </GridContainer>
            
        </div>
    );
  }
}

export default withStyles(notFoundPage)(NotFound);