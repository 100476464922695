import { container, title } from "assets/jss/material-kit-react.jsx";

const notFoundPage = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    marginBottom: "1rem",
    //marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    height: "50%",
  },
  subtitle: {
    fontSize: "1.313rem",
    //margin: "10px 50px 30px 50px",
    textAlign: "center",
  },
  notFound:{
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    //verticalAlign: "middle",
  },
  inviteLink: {
    backgroundColor: "#009688",
    color:"white",
    '&:hover': {
      color: "#0000ee",
    },
    //marginLeft: "2em",
    padding:"1em 2em",
    borderRadius:".5em",
    fontWeight: "bolder",
    width: "35%",
    textAlign: "center",
  },
  error: {
  },
  img:{
    marginTop: "10%",
    width: "25%",
    display: "flex",
    flexDirection: "center",
  },
};

export default notFoundPage;
